<div class="container p-0">
	<div class="row mx-0 px-3 justify-content-between align-items-center dialog-header">
		<div class="col-auto px-0">
			<h4 class="heading-4-regular fg-general-5 m-0">
				<span>{{ 'n3475' | translations }}</span>

				<span>!</span>
			</h4>
		</div>

		<div class="col-auto px-0">
			<button mat-icon-button mat-dialog-close class="close-btn" (click)="close()">
				<mat-icon class="justify-content-center material-icons-outlined fg-general-4 fs-20px">close</mat-icon>
			</button>
		</div>
	</div>

	<div class="py-4 px-3">
		<div class="row justify-content-center align-items-center">
			<div class="col-auto">
				<img [ngSrc]="'assets/images/free-ad-banner.webp'" loading="eager" fetchpriority="high" width="196" height="196" />
			</div>
		</div>

		<div class="text-center mt-3">
			<span class="text-title-20px-medium fg-general-5 d-block lh-24px">{{ 'n3476' | translations }}</span>

			<span class="text-title-20px-medium fg-general-5 d-block lh-24px">{{ 'n3477' | translations }}</span>

			<span class="text-small-14px-regular fg-general-4 d-block mt-3 lh-18px">{{ 'n3478' | translations }}</span>
		</div>
	</div>

	<div class="px-3 dialog-actions">
		<button mat-flat-button class="w-100 text-body-regular h-40px fg-error-1" color="primary" (click)="goToPlaceAd()">{{ 'n3475' | translations }}</button>
	</div>
</div>
