import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APP_ROUTE } from '@core/constants';
import { AuthService } from '@core/data-access/services/auth.service';
import { SellerType } from '@core/enums';
import { ClientUser } from '@core/models';

@Component({
	selector: 'findqo-post-ad-dialog',
	templateUrl: './post-ad-dialog.component.html',
	styleUrls: ['./post-ad-dialog.component.scss'],
})
export class PostAdDialogComponent {
	constructor(
		private dailogRef: MatDialogRef<PostAdDialogComponent>,
		private router: Router,
		private readonly auth: AuthService
	) {}

	user: ClientUser = this.auth.user();

	goToPlaceAd(): void {
		const SELLER_TYPE_ID = +this.user.hasBusinessProfile ? SellerType.BUSINESS : SellerType.INDIVIDUAL;
		this.router.navigate([APP_ROUTE.placeAdCategory], { queryParams: { sellerTypeId: SELLER_TYPE_ID } });
		this.dailogRef.close();
	}

	close(): void {
		this.dailogRef.close();
	}
}
